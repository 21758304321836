/* eslint-disable camelcase */
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { PropTypes } from 'prop-types';
import useStyles from 'isomorphic-style-loader/useStyles';
import { AnalyticsLinkComponent } from '@airtel-tv/analytics';
import { ThumborImage } from '@airtel-tv/ui-lib/atoms';
import { RAIL_TYPES } from '@airtel-tv/constants';
import styles from './XTopNavigationBar.scss';
import DeviceUtil from '@airtel-tv/utils/DeviceUtil';
import { IMG_TYPE } from '@airtel-tv/constants/ImagesConst';
import { getLottieFiles } from '@airtel-tv/utils/GlobalUtil';
import Lottie from 'lottie-react';

const XTopNavigationBar = (props) => {
    useStyles(styles);
    const {
        zionDisplay: {
            rightIcons = [
                {},
            ],
        } = {},
        showSubscriptionRibbon,
        hideRenewBanner,
        isAuthenticated,
    } = props;
    const isMobile = DeviceUtil?.getDeviceUtil()?.isMobile();
  

    const tagDetails = JSON.parse(rightIcons);
    // tagDetails[0].type = "lottie";
    // tagDetails[0].img = "https://image.airtel.tv/grandslam/content/2024_7_30/Expiring-1x.json";
    const {
        webImg = '',
        webDeepLink = '',
        webHeight = '',
        webWidth = '',
        asset_name = '',
        height = '',
        width = '',
        type = '',
        img = '',
        marginRight = 16,
        marginTop = 16,
        webMarginTop = 20,
        webMarginRight = 20,
    } = tagDetails[0] || {};
    const tagHeight = isMobile ? height : webHeight;
    const tagWidth = isMobile ? width : webWidth;
    const tagMarginTop = isMobile ? marginTop : webMarginTop;
    const tagMarginRight = isMobile ? marginRight : webMarginRight;
    const imageSettings = { 300: 1000 };
    useEffect(() => {
        if (type.toLowerCase() === IMG_TYPE.LOTTIE && img) {
            const lottieData = getLottieFiles(img);
            lottieData.then((res) => {
                handleAnimationData(res);
            });
        }
    }, []);
    const [
        animationData,
        handleAnimationData,
    ] = useState(null);
    const style = {
        width: `auto`,
        height: `${tagHeight}px`,
        marginTop: `${tagMarginTop}px`,
        marginRight: `${tagMarginRight}px`,
    }
    return (
        <AnalyticsLinkComponent
            to={webDeepLink}
            className={`top-navigation-tag-container`}
            meta={{
                asset_name,
                rail_type: RAIL_TYPES.X_TOP_NAVIGATION_BAR,
            }}
        >
            {animationData ? 
                <Lottie
                    loop
                    animationData={animationData}
                    play
                    style={style}
                /> : 
                <ThumborImage
                    src={webImg}
                    imageStyle={style}
                    imageSettings={imageSettings}
                />
            }
        </AnalyticsLinkComponent>
    );
};

XTopNavigationBar.propTypes = {
    zionDisplay: PropTypes.object,
};

XTopNavigationBar.defaultProps = {
    zionDisplay: {},
};

const mapStateToProps = ({
    appConfig: { showSubscriptionRibbon = true } = {},
    notify: { hideRenewBanner = false } = {},
    authConfig: { isAuthenticated = false } = {},
}) => ({
    showSubscriptionRibbon,
    hideRenewBanner,
    isAuthenticated,
});

export default connect(mapStateToProps)(XTopNavigationBar);
